export interface IRoutes {
  [key: string]: {
    key: string;
    path: string;
    mustBeAuthenticated?: boolean;
    mustBeUnauthenticated?: boolean;
    loader: () => Promise<
      React.ComponentType<any> | { default: React.ComponentType<any> }
    >;
  };
}

const routes: IRoutes = {
  home: {
    key: "/",
    path: "/",
    mustBeAuthenticated: true,
    loader: () => import("./pages/home")
  },
  projects: {
    key: "/projects",
    path: "/projects",
    mustBeAuthenticated: true,
    loader: () => import("./pages/projects")
  },
  project: {
    key: "/projects/:id",
    path: "/projects/:id",
    mustBeAuthenticated: true,
    loader: () => import("./pages/projects/project")
  },
  correction: {
    key: "/data-correction",
    path: "/data-correction",
    mustBeAuthenticated: true,
    loader: () => import("./pages/correction")
  },
  administration: {
    key: "/administration/users",
    path: "/administration/users",
    mustBeAuthenticated: true,
    loader: () => import("./pages/administration/Users")
  },
  profile: {
    key: "/profile",
    path: "/profile",
    mustBeAuthenticated: true,
    loader: () => import("./pages/auth/Profile")
  },
  login: {
    key: "/auth/login",
    path: "/auth/login",
    mustBeUnauthenticated: true,
    loader: () => import("./pages/auth/Login")
  },
  forgetPassword: {
    key: "/auth/forget-password",
    path: "/auth/forget-password",
    mustBeUnauthenticated: true,
    loader: () => import("./pages/auth/ForgetPassword")
  }
};

export default routes;
