import { FC, ReactElement } from "react";
import { Spin } from "antd";

interface IProps {}

const Loader: FC<IProps> = (): ReactElement => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Spin />
    </div>
  );
};

export default Loader;
