import {
  REQUEST_UNAUTHORIZED,
  REQUEST_PENDING,
  REQUEST_SUCCESS,
  REQUEST_FAILURE,
  RESET_ERROR,
  RequestActionTypes,
  RequestState
} from "./types";

const RequestReducer = function (
  state: RequestState = {
    isFetching: false,
    error: undefined
  },
  action: RequestActionTypes
) {
  const { type } = action;
  switch (type) {
    case REQUEST_PENDING:
      return { ...state, isFetching: true, error: undefined };
    case REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: "Request Failure"
      };
    case REQUEST_UNAUTHORIZED:
      return {
        ...state,
        isFetching: false,
        error: "Request Unauthorized"
      };
    case REQUEST_SUCCESS:
      return { ...state, isFetching: false, error: undefined };
    case RESET_ERROR:
      return { ...state, isFetching: false, error: undefined };
    default:
      return state;
  }
};

export default RequestReducer;
