import { applyMiddleware, createStore } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";

import rootReducer from "../reducers";

const configureStoreDev = (preloadedState) => {
  const store = createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(thunk, logger)
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("../reducers", () => {
      store.replaceReducer(rootReducer);
    });
  }

  return store;
};

const configureStoreProd = (preloadedState) =>
  createStore(rootReducer, preloadedState, applyMiddleware(thunk));

export default process.env.NODE_ENV === "production"
  ? configureStoreProd
  : configureStoreDev;
