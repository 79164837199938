import {
  USER_LOGIN_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_FETCH_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  AuthState,
  AuthenticationAction
} from "./types";

const cachedUserOrNull = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user") as string)
  : null;

const initState: AuthState = {
  isAuthenticating: false,
  user: cachedUserOrNull,
  error: null
};

const auth = (
  state: AuthState = initState,
  action: AuthenticationAction | undefined
) => {
  if (action === undefined) {
    return initState;
  }
  const { type, payload } = action;
  switch (type) {
    case USER_LOGIN_REQUEST:
      return {
        user: null,
        isAuthenticating: true,
        error: null
      };
    case USER_UPDATE_SUCCESS:
    case USER_FETCH_SUCCESS:
      return {
        user: payload,
        isAuthenticating: false,
        error: null
      };
    case USER_LOGIN_FAILURE:
      return {
        user: null,
        isAuthenticating: false,
        error: payload
      };
    case USER_LOGOUT_REQUEST:
      return state;
    case USER_LOGOUT_SUCCESS:
      return {
        user: null,
        isAuthenticating: false,
        error: null
      };
    default:
      return state;
  }
};

export default auth;
