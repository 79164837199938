import ReactDOM from "react-dom";
import Loadable from "react-loadable";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import Loading from "components/loader";

import configureStore from "./redux/store";

import * as serviceWorker from "./serviceWorker";

// import application routes
import routes from "./routes";

// Import stylesheet
import "./app.less";

const ErrorBoundary = Loadable({
  loader: () => import("./pages/error/error-boundary"),
  loading: Loading
});

const Router = Loadable({
  loader: () => import("./modules/router/index"),
  loading: Loading
});

// Create redux store with history
const store = configureStore({});

// get mounting node
const MOUNT_NODE = document.getElementById("root");

const render = async () => {
  if (MOUNT_NODE) {
    ReactDOM.render(
      <Provider store={store}>
        <BrowserRouter>
          <ErrorBoundary>
            <Router routes={routes} />
          </ErrorBoundary>
        </BrowserRouter>
      </Provider>,
      MOUNT_NODE
    );
  }
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(["./modules/router", "./routes"], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });
}

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
// if (process.env.NODE_ENV === "production") {
//   serviceWorker.register();
// }
