export const REQUEST_PENDING = "REQUEST_PENDING";
export const REQUEST_FAILURE = "REQUEST_FAILURE";
export const REQUEST_SUCCESS = "REQUEST_SUCCESS";
export const REQUEST_UNAUTHORIZED = "REQUEST_UNAUTHORIZED";
export const REQUEST_FORBIDDEN = "REQUEST_FORBIDDEN";
export const RESET_ERROR = "RESET_ERROR";

interface SuccessPayload {
  endpoint: string;
  data: object;
}

interface ErrorPayload {
  endpoint: string;
  error: object;
  status: number;
}

interface PendingPayload {
  endpoint: string;
}

export interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS;
  payload: SuccessPayload;
}

export interface RequestPendingAction {
  type: typeof REQUEST_PENDING | typeof RESET_ERROR;
  payload: PendingPayload;
}

export interface RequestFailAction {
  type:
    | typeof REQUEST_FAILURE
    | typeof REQUEST_UNAUTHORIZED
    | typeof REQUEST_FORBIDDEN;
  payload: ErrorPayload;
}

export interface RequestState {
  isFetching: boolean;
  error?: string | undefined;
}

export type RequestActionTypes =
  | RequestPendingAction
  | RequestSuccessAction
  | RequestFailAction;
