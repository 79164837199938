import { combineReducers } from "redux";

import auth from "modules/auth/reducers";
import request from "modules/request/reducers";

const AppReducers = combineReducers({
  /* app’s top-level reducers */
  auth,
  request
});

const rootReducer = (state, action) => {
  if (action === undefined || action.type === undefined) {
    return state;
  }
  return AppReducers(state, action);
};

export default rootReducer;
