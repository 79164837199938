// Authentication Reducer Constants
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_FETCH_SUCCESS = "USER_FETCH_SUCCESS";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const CLEAR_AUTH_ERROR = "CLEAR_AUTH_ERROR";
export const LOGIN_MODAL = "LOGIN_MODAL";
export const REGISTER_MODAL = "REGISTER_MODAL";
export const RESET_PASSWORD_MODAL = "RESET_PASSWORD_MODAL";

export interface AuthState {
  isAuthenticating: boolean;
  user: object | null;
  error: string | null;
}

export interface AccessTokenResponse {
  token_type: "Bearer";
  expires_in: number;
  access_token: string;
  refresh_token: string;
}

export interface AuthenticationAction {
  type:
    | typeof USER_LOGIN_REQUEST
    | typeof USER_LOGIN_SUCCESS
    | typeof USER_LOGIN_FAILURE
    | typeof USER_LOGOUT_REQUEST
    | typeof USER_FETCH_SUCCESS
    | typeof USER_UPDATE_SUCCESS
    | typeof USER_LOGOUT_SUCCESS;
  payload?: object | string | null;
}
